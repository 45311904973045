import { getAnalyticsData, isSponsoredArticle } from './utils';
import { setWidgetEmbedEvent, setWidgetViewableEvent } from './analytics';
import {
  Campaign,
  findBlockIndexToInject,
  HeaderCampaign,
  InArticleCampaign,
  injectHeaderCampaign,
  injectInArticleCampaign,
} from './injection';
import { clientApi, getSiteParameters, PageDataObject } from './providers/clientApi';
import { getCampaignsFromCampaignManagerService } from './providers/campaignManagerService';

declare global {
    interface Window {
        mmClientApi: any[];
    }
}

export const injectArticleCampaigns = (endpoint: string) => {
  clientApi.getPageData(async (pageDataObject: PageDataObject) => {
    const { siteName, countryCode, localTime, language, commercialTags } = getSiteParameters(pageDataObject);

    if (isSponsoredArticle(commercialTags)) {
      return;
    }

    const campaigns = await getCampaignsFromCampaignManagerService(endpoint)(siteName, countryCode, localTime, language, commercialTags);
    const headerCampaigns = campaigns.filter((campaign: Campaign) => campaign.position === 'header');
    const inArticleCampaigns = campaigns.filter((campaign: Campaign) => campaign.position === 'inArticle');

    headerCampaigns.forEach((campaign: HeaderCampaign) => {
      injectHeaderCampaign(campaign);
    });

    inArticleCampaigns.forEach((campaign: InArticleCampaign, index: number) => {
      const { blockNames } = pageDataObject;
      const blockIndex = findBlockIndexToInject(blockNames);
      if (blockIndex === -1) {
        return;
      }

      const blockDataId = `campaign-block-${index}`;
      const { label } = getAnalyticsData(campaign);
      setWidgetEmbedEvent(label, blockDataId);
      setWidgetViewableEvent(label, blockDataId);

      injectInArticleCampaign(campaign, blockIndex, blockDataId);
    });
  });
};
