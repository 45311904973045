import { clientApi, ViewabilityArticleBlockEventObject } from './providers/clientApi';

const WIDGET_EVENTS_CATEGORY = 'Article page';
const WIDGET_EMBED_ACTION = 'widget embed';
const WIDGET_VIEWABLE_ACTION = 'widget viewable impression';

const sendEventToAnalytics = (category: string, action: string, label: string) => {
  clientApi.analytics({
    event_category: category,
    event_action: action,
    event_label: label,
  });
};

export const setWidgetEmbedEvent = (label: string, blockDataId: string) => {
  clientApi.injectArticleBlockByIndexEvent((dataId: string) => {
    if (dataId === blockDataId) {
      sendEventToAnalytics(WIDGET_EVENTS_CATEGORY, WIDGET_EMBED_ACTION, label);
    }
  });
};

export const setWidgetViewableEvent = (label: string, blockDataId: string) => {
  clientApi.viewabilityArticleBlockEvent((viewabilityArticleBlockEventObject: ViewabilityArticleBlockEventObject) => {
    if (viewabilityArticleBlockEventObject.id === blockDataId) {
      sendEventToAnalytics(WIDGET_EVENTS_CATEGORY, WIDGET_VIEWABLE_ACTION, label);
    }
  });
};
