export interface PageDataObject {
    siteName: string;
    countryCode: string;
    blockNames: string[];
    language: string;
    tags: string[];
}

export interface ViewabilityArticleBlockEventObject {
    id: string;
    index: number;
    templateName: string;
}

interface InjectArticleBlockByIndexObject {
    html: string;
    dataId: string;
    index: number;
    script?: string;
}

interface InjectVideoLogoObject {
    videoPath: string;
}

interface AnalyticsObject {
    event_category: string;
    event_action: string;
    event_label: string;

    [key: string]: string;
}

export const getSiteParameters = (pageDataObject: PageDataObject) => {
  const { siteName, countryCode, language, tags } = pageDataObject;
  const commercialTags = tags || [];
  const urlParams = new URLSearchParams(window.location.search);
  const urlCommercialTags = urlParams.get('tags');
  const urlLocalTime = urlParams.get('localTime');

  return {
    siteName: urlParams.get('site') || siteName,
    countryCode: urlParams.get('country-code') || countryCode,
    localTime: urlLocalTime ? new Date(urlLocalTime) : new Date(`${new Date()}UTC`),
    language: urlParams.get('language') || language,
    commercialTags: urlCommercialTags !== null ? urlCommercialTags.split(',') : commercialTags,
  };
};

const getClientApi = () => {
  window.mmClientApi = window.mmClientApi || [];
  return window.mmClientApi;
};

const injectArticleBlockByIndexArray = (injectArticleBlockByIndexObjects: InjectArticleBlockByIndexObject[]) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('injectArticleBlockByIndexArray', injectArticleBlockByIndexObjects);
};

const viewabilityArticleBlockEvent = (callback: (viewabilityArticleBlockEventObject: ViewabilityArticleBlockEventObject) => void) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('viewabilityArticleBlockEvent', callback);
};

const getPageData = (callback: (pageDataObject: PageDataObject) => void) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('getPageData', callback);
};

const injectArticleBlockByIndexEvent = (callback: (dataId: string) => void) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('injectArticleBlockByIndexEvent', callback);
};

const analytics = (analyticsObject: AnalyticsObject) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('analytics', analyticsObject);
};

const injectVideoLogo = (injectVideoLogoObject: InjectVideoLogoObject) => {
  const mmClientApi = getClientApi();
  mmClientApi.push('injectVideoLogo', injectVideoLogoObject);
};

export const clientApi = {
  injectArticleBlockByIndexArray,
  viewabilityArticleBlockEvent,
  getPageData,
  injectArticleBlockByIndexEvent,
  analytics,
  injectVideoLogo,
};
