import { InArticleCampaign } from './injection';

export const htmlToElement = (html: string) => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();

  return template.content.firstElementChild as HTMLElement;
};

export const isSponsoredArticle = (commercialTags: string[]) => (
  commercialTags.includes('sponsored')
);

export const getAnalyticsData = (campaign: InArticleCampaign) => {
  const element = htmlToElement(campaign.payload.embedCode);

  const category = element.getAttribute('data-viewability_category') || '';
  const action = element.getAttribute('data-viewability_action') || '';
  const label = element.getAttribute('data-viewability_label') || '';

  return {
    category,
    action,
    label,
  };
};
