export const getCampaignsFromCampaignManagerService = (endpoint: string) => async (site: string, country: string, localTime: Date, language: string, tags: string[]) => {
  const query = `site=${site}&country=${country}&localTime=${localTime.toJSON()}&language=${language}&tags=${tags.join(',')}`;
  const request = `${endpoint}/campaigns/active?${query}`;

  const response = await fetch(request);
  if (!response.ok) {
    return [];
  }

  const json = await response.json();
  return json.data;
};
