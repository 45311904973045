import { htmlToElement } from './utils';
import { clientApi } from './providers/clientApi';

export interface Campaign {
    position: string;
    type: string;
}

export interface HeaderCampaign extends Campaign {
    payload: {
        src: string;
    };
}

export interface InArticleCampaign extends Campaign {
    payload: {
        src: string;
        embedCode: string;
    };
}

const createScriptInjector = (script: HTMLElement, index: number) => `
    const scriptElement${index} = document.createElement('script');
    scriptElement${index}.innerHTML = '${script.innerHTML}';
    ${Array.from(script.attributes)
    .map(attr => `scriptElement${index}.setAttribute('${attr.name}', '${attr.value}');
    `).join('')}
    document.head.appendChild(scriptElement${index});
    `;

const getCampaignPayload = (src: string) => {
  const element = htmlToElement(src);

  const scriptElements = Array.from(element.getElementsByTagName('script'));
  const script = scriptElements.map(createScriptInjector).join('');

  scriptElements.forEach(scriptElement => scriptElement.remove());
  const html = element.outerHTML;

  return {
    html,
    script,
  };
};

export const injectHeaderCampaign = (campaign: HeaderCampaign) => {
  const { src } = campaign.payload;
  const videoPath = src.replace(/\.gif$/, '.mp4');
  clientApi.injectVideoLogo({ videoPath });
};

export const injectInArticleCampaign = (campaign: InArticleCampaign, index: number, dataId: string) => {
  const { html, script } = getCampaignPayload(campaign.payload.embedCode);
  clientApi.injectArticleBlockByIndexArray([{ html, dataId, index, script }]);
};

export const findBlockIndexToInject = (blockNames: string[]) => {
  const articleLength = blockNames.length;
  const minIndexToInject = Math.floor(articleLength / 2);
  const maxIndexToInject = articleLength - 3;

  return blockNames.findIndex((blockName, index, array) => {
    const isPreviousBlockInlineText = array[index - 1] === 'inline-text';
    const isCurrentBlockInlineTextOrTitle = blockName === 'inline-text' || blockName === 'title';
    const isValidIndexToInject = index >= minIndexToInject && index <= maxIndexToInject;

    return isValidIndexToInject && isPreviousBlockInlineText && isCurrentBlockInlineTextOrTitle;
  });
};
